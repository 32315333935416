<template>
  <b-card>
    <section>
      <div
        class="d-flex justify-content-end"
      >
        <b-button
          :to="{name: 'champion-monthly-resources'}"
          variant="primary"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
          />
          Resource Engagment Report
        </b-button>
      </div>
      <h4 class="text-left">
        {{ downLoadsThisMonth }} Resource downloads and views this month
      </h4>
      <h5 class="text-left text-muted">
        {{ deltaDownload }}
      </h5>
      <aom-skeleton-loader
        v-if="isLoading"
        :loader-type="loaderTypes.SHORT"
      />
      <apexchart
        v-else
        type="line"
        :options="options"
        :series="options.series"
        height="240px"
      />
    </section>
  </b-card>
</template>
  
<script>
import {
  BCard,
  BButton,
} from 'bootstrap-vue';
//eslint-disable-next-line
import { required } from "@validations";
import { reportService} from '@services';
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import {
  getValidationState,
  localeDateStringFromIsoDateTime,
  makeErrorToast
} from "@/libs/utils";    
import { loaderTypes } from '@/models';


export default {
  name: 'DailyDownloadsReport',
  components: {
    BCard,
    BButton,
    AomSkeletonLoader,
    AomSkeletonLoader
  },
  props: {
    groupId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      downLoadsThisMonth: 0,
      deltaDownloadsCount: 0,
      isLoading: false,
      year: undefined,
      month: undefined,
      selectedGroups:[],
      programGroups: [],
      options: {
        colors: ["#9bcc65"],
        series: [],
        xaxis: {
          categories: [],
          tickAmount: 10,
          labels: {
            rotate: 0
          }
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: function(val) {
              return val.toFixed(0);
            }
          }
        },
        chart: {
          height: 240,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          x: {
            formatter: day => {
              const dateString = `${this.year}-${this.month}-${day}`;
              return localeDateStringFromIsoDateTime(dateString);
            }
          }
        }
      }
    };
  },
  computed: {
    deltaDownload() {
      return this.deltaDownloadsCount >= 0 ? `+${this.deltaDownloadsCount} compared to last month` : `${this.deltaDownloadsCount} compared to last month`; 
    }
  },
  watch: {
    groupId: {
      handler(n) {
        if(n) {
          this.fetchDownLoadsReport();       
        }
      },
      immediate: true
    },
    downLoadsThisMonth(n) {
      if(n) {
        this.$emit('count', n);
      }
    }
  },
  created () {
    this.fetchDownLoadsReport(); 
  },
  methods: {
    async fetchDownLoadsReport() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const response = await reportService.getDailyDownloadsChartData(programId,  this.groupId);
        const {data, delta_user_downloads_count, user_downloads_count, month, x_data, year} = response.data;
        this.options.series =  [{name: "Total Downloads/Views", data }]; 
        // eslint-disable-next-line camelcase
        this.downLoadsThisMonth = user_downloads_count;
        // eslint-disable-next-line camelcase
        this.deltaDownloadsCount = delta_user_downloads_count;
        this.month = month;
        this.year = year;
        this.options = {
          ...this.options,
          xaxis: {
            ...this.options.xaxis,
            max: x_data.length,
            categories: x_data
          }
        };
      } catch(e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }  
    },
  },
  setup() {
    return {
      localeDateStringFromIsoDateTime,
      getValidationState,
      loaderTypes
    };
  }
};
</script>
<style lang="scss" scoped>

::v-deep .apexcharts-menu-item.exportCSV {
  display: none;
}
.group-filter {
    width: 25%;
    min-width: 25%;
}
</style> 