<template>
  <b-card>
    <div
      class="d-flex justify-content-end"
    >
      <b-button
        :to="{name: 'champion-monthly-reports'}"
        variant="primary"
      >
        <feather-icon
          icon="EyeIcon"
          size="16"
        />
        Active Session Report
      </b-button>
    </div>
    <h4 class="text-left">
      {{ loginThisMonth }} sessions this month
    </h4>
    <h5 class="text-left text-muted">
      {{ deltaLogin }}
    </h5>
    <b-row>
      <b-col
        sm="12"
      >
        <aom-skeleton-loader
          v-if="isLoading"
          :loader-type="loaderTypes.SHORT"
        />
    
        <div v-else>
          <apexchart
            type="line"
            :options="options"
            :series="options.series"
            height="240px"
          />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
  
<script>
import {
  BCard,
  BButton, 
  BRow, 
  BCol,
} from 'bootstrap-vue';
//eslint-disable-next-line
import { required } from "@validations";
import { reportService } from '@services';
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import {
  getValidationState,
  localeDateStringFromIsoDateTime,
  makeErrorToast
} from "@/libs/utils";
import { loaderTypes } from '@/models';
export default {
  name: 'DailyLoginReport',
  components: {
    BCard,
    BButton,
    BRow, 
    BCol,
    AomSkeletonLoader
  },
  props: {
    groupId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      loginThisMonth: 0,
      deltaLoginCount: 0,
      isLoading: false,
      year: undefined,
      month: undefined,
      programGroups: [],
      options: {
        colors: ["#9bcc65"],
        series: [],
        xaxis: {
          categories: [],
          tickAmount: 10,
          labels: {
            rotate: 0
          }
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: function(val) {
              return val.toFixed(0);
            }
          }
        },
        chart: {
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          // show: true,
          // borderColor: '#ccc',
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          x: {
            formatter: day => {
              const dateString = `${this.year}-${this.month}-${day}`;
              return localeDateStringFromIsoDateTime(dateString);
            }
          }
        }
      }
    };
  },
  computed: {
    deltaLogin() {
      return this.deltaLoginCount >= 0 ? `+${this.deltaLoginCount} compared to last month` : `${this.deltaLoginCount} compared to last month`; 
    }
  },
  watch: {
    groupId: {
      handler(n) {
        if(n) {
          this.fetchLoginReport();       
        }
      },
      immediate: true
    },
    loginThisMonth(n) {
      if(n) {
        this.$emit('count', n);
      }
    }
  },
  created () {
    this.fetchLoginReport(); 
  },
  methods: {
    async fetchLoginReport() {
      try {
        this.isLoading = true;
        const programId = this.$route.params.id;
        const response = await reportService.getDailyLoginsChartData(programId, this.groupId);
        const {data, delta_logins_count, logins_count, month, x_data, year} = response.data;
        this.options.series =  [{name: "Total sessions", data }]; 
        // eslint-disable-next-line camelcase
        this.loginThisMonth = logins_count;
        // eslint-disable-next-line camelcase
        this.deltaLoginCount = delta_logins_count;
        this.month = month;
        this.year = year;
        this.options = {
          ...this.options,
          xaxis: {
            ...this.options.xaxis,
            max: x_data.length,
            categories: x_data
          }
        };
      } catch(e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      }  
    }
  },
  setup() {
    return {
      localeDateStringFromIsoDateTime,
      getValidationState,
      loaderTypes
    };
  }
};
</script>
<style lang="scss" scoped>
::v-deep .apexcharts-menu-item.exportCSV {
  display: none;
}
.group-filter {
    width: 25%;
    min-width: 25%;
}
#daily-login {
  max-height: 240px;
}

</style>